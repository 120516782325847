import React from 'react';
// import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Button } from 'semantic-ui-react';

const SocialLink = ({ provider }) => {
  return (
    <a href={`${process.env.REACT_APP_CMS_HOST}/connect/${provider}`} className="link">
    <Button color='facebook'>
      <Icon name='facebook' /> Facebook
    </Button>
      {/* <Button type="button" social={provider} style={{ width: '100%' }}>
        <i className={`fa fa-${provider}`} />
        <Icon name='facebook'/>
        {capitalize(provider)}
      </Button> */}
    </a>
  );
}

SocialLink.propTypes = {
  provider: PropTypes.string.isRequired,
};

export default SocialLink;