import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Icon, Menu, Sidebar } from 'semantic-ui-react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { HOME, ORDERS, MY_PROFILE } from '../../';
import { displayIfCustomer } from '../../components/utils/role';

const BottomMenu = ({ location, isMenuVisible, ...props }) => {
  const { pathname } = location;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(true);
  }, [pathname]);

  return (
    <>
      <Sidebar
        style={{ paddingTop: 0 }}
        as={Menu}
        borderless
        widths={4}
        fluid
        animation="overlay"
        icon="labeled"
        direction="bottom"
        onHide={() => {}}
        visible={visible && isMenuVisible}
      >
        <Menu.Item as={Link} to={HOME} active={pathname === HOME}>
          <Icon name="home" />
          Home
        </Menu.Item>
        <Menu.Item as={Link} to={ORDERS} active={pathname.indexOf(ORDERS) > -1}>
          <Icon name="unordered list" />
          Orders
        </Menu.Item>
        <Menu.Item disabled as="a">
          <Icon name="camera" />
          Channels
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={MY_PROFILE}
          active={pathname.indexOf(MY_PROFILE) > -1}
        >
          <Icon name="user" />
          Me
        </Menu.Item>
      </Sidebar>
      <Waypoint
        onEnter={({ previousPosition, currentPosition, event }) => {
          setVisible(!event);
        }}
        onLeave={() => {
          setVisible(true);
        }}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    isMenuVisible: state.bottomMenu.isVisible
  };
};

export default compose(
  withRouter,
  displayIfCustomer,
  connect(mapStateToProps)
)(BottomMenu);
