import React, { useState, useEffect, useCallback } from 'react';
import 'whatwg-fetch';
import {
  Button,
  Container,
  Dimmer,
  Loader,
  Segment,
  Header,
  Message,
  TransitionablePortal,
  Input,
  Dropdown
} from 'semantic-ui-react';
import { withJwt } from '../../components/utils/role';
import { handleErrors } from '../../components/Common';

const LONG_PULSE = 100;
const MID_PULSE = 60;
const SHORT_PULSE = 30;
const options = [
  {
    key: 'longPulse',
    text: 'Long pulse',
    value: 'longPulse'
  },
  {
    key: 'midPulse',
    text: 'Mid pulse',
    value: 'midPulse'
  },
  {
    key: 'shortPulse',
    text: 'Short pulse',
    value: 'shortPulse'
  }
];

const fetchGenerateKey = ({ serialNum, jwt, payload }) => {
  return fetch(
    `${process.env.REACT_APP_CMS_HOST}/locks/generate-key?serialNum=${serialNum}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ serialNum, ...payload })
    }
  )
    .then(handleErrors)
    .then(response => response.json());
};

const SERVICE_UUID = 'ab0828b1-198e-4351-b779-901fa0e0371e';
const CHARACTERISTIC_UUID_RX = '4ac8a682-9736-4e5d-932b-e9b31405049c';
const CHARACTERISTIC_UUID_TX = '0972ef8c-7613-4075-ad52-756f33d4da91';
const SUCCESS_CHARACTERISTIC_UUID_TX = '12345678-7613-4075-ad52-756f33d4da91';

const getSerialNumber = characteristic =>
  characteristic
    .readValue()
    .then(value => {
      console.log(value.getUint16(0, true));
      return value.getUint16(0, true);
    })
    .catch(error => {
      console.log('Argh! getSerialNumber ' + error);
    });

const Lock = props => {
  const { jwt } = props;
  const [time, setTime] = useState(350);
  const [enable, setEnable] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [pulseType, setPulseType] = useState('longPulse');
  const [pulse, setPulse] = useState({
    longPulse: 100,
    longNum: 0,
    midPulse: 60,
    midNum: 0,
    shortPulse: 30,
    shortNum: 0
  });
  //   const [selectedDevice, setSelectedDevice] = useState(null);
  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }

    if (showError) {
      setTimeout(() => {
        setShowError(false);
        setEnable(true);
      }, 2000);
    }
  });

  const disconnectHandler = useCallback(
    device => {
      setEnable(true);
    },
    [setEnable]
  );

  const connectHandler = useCallback(
    selectedDevice => {
      setEnable(false);
      selectedDevice.addEventListener(
        'gattserverdisconnected',
        disconnectHandler
      );
    },
    [setEnable]
  );

  return (
    <Container>
      <Input
        placeholder="long"
        value={pulse.longNum}
        onChange={(e, { value }) =>
          setPulse(p => ({
            ...p,
            longNum: value
          }))
        }
      />
      <Input
        placeholder="mid"
        value={pulse.midNum}
        onChange={(e, { value }) =>
          setPulse(p => ({
            ...p,
            midNum: value
          }))
        }
      />
      <Input
        placeholder="short"
        value={pulse.shortNum}
        onChange={(e, { value }) =>
          setPulse(p => ({
            ...p,
            shortNum: value
          }))
        }
      />
      <Button
        primary
        disabled={!enable}
        onClick={async () => {
          const device = await navigator.bluetooth.requestDevice({
            filters: [
              // { services: [SERVICE_UUID] },
              // { name: 'BoxifyDongle-ABCD123' }
              { name: 'BoxifyDongle-QWER123' }
            ]
          });
          connectHandler(device);
          const characteristics = await device.gatt
            .connect()
            .then(server => {
              return server.getPrimaryService(SERVICE_UUID);
            })
            .then(service => {
              return service.getCharacteristics();
            })
            .catch(error => {
              console.log(error);
            });
          try {
            const getSerialChar = characteristics.find(
              ({ uuid }) => uuid === CHARACTERISTIC_UUID_TX
            );
            const serialNum = await getSerialNumber(getSerialChar);
            const notifyChar = characteristics.find(
              ({ uuid }) => uuid === SUCCESS_CHARACTERISTIC_UUID_TX
            );
            notifyChar.startNotifications().then(_ => {
              console.log('> Notifications started');
              notifyChar.addEventListener(
                'characteristicvaluechanged',
                event => {
                  let value = event.target.value;
                  let a = [];

                  for (let i = 0; i < value.byteLength; i++) {
                    a.push(String.fromCharCode(value.getUint8(i)));
                  }
                  console.log('> ' + a.join(''));
                  const result = a.join('');
                  if (result === 'SUCCESS') {
                    setShowSuccess(true);
                  } else {
                    setShowError(true);
                  }
                  device.gatt.disconnect();
                }
              );
            });

            const { token } = await fetchGenerateKey({
              serialNum,
              jwt,
              payload: { ...pulse }
            }).catch(e => {
              setShowError(true);
            });
            const writeTokenChar = characteristics.find(
              ({ uuid }) => uuid === CHARACTERISTIC_UUID_RX
            );
            const encoder = new TextEncoder('utf-8');
            console.log('writing token');
            writeTokenChar
              .writeValue(encoder.encode(token))
              .then(r => console.log('write success'));
          } catch (e) {
            // alert('Unlock failed.');
            setShowError(true);
          }
        }}
      >
        Open lock
      </Button>
      <TransitionablePortal open={showSuccess}>
        <Message success header="Unlock successful." />
      </TransitionablePortal>
      <TransitionablePortal open={showError}>
        <Message error header="Unlock failed." />
      </TransitionablePortal>
      <Dimmer inverted active={!enable}>
        <Loader />
      </Dimmer>
    </Container>
  );
};

export default withJwt(Lock);
