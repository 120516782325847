import React, { useState, useEffect, useCallback } from 'react';
import 'whatwg-fetch';
import {
  Button,
  Container,
  Input,
  TransitionablePortal,
  Dimmer,
  Loader,
  Message
} from 'semantic-ui-react';
import QrReader from 'react-qr-reader';
import { withJwt } from '../../components/utils/role';
import { handleErrors } from '../../components/Common';

import getParameterByName from '../../components/utils/getParameterByName';

const SCAN = 'scan';
const USER_INPUT = 'USER_INPUT';

const fetchGenerateKey = ({ serialNum, jwt, payload }) => {
  return fetch(
    `${process.env.REACT_APP_CMS_HOST}/locks/generate-key?serialNum=${serialNum}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ serialNum, ...payload })
    }
  )
    .then(handleErrors)
    .then(response => response.json());
};

const SERVICE_UUID = 'ab0828b1-198e-4351-b779-901fa0e0371e';
const CHARACTERISTIC_UUID_RX = '4ac8a682-9736-4e5d-932b-e9b31405049c';
const CHARACTERISTIC_UUID_TX = '0972ef8c-7613-4075-ad52-756f33d4da91';
const SUCCESS_CHARACTERISTIC_UUID_TX = '12345678-7613-4075-ad52-756f33d4da91';

const getSerialNumber = characteristic =>
  characteristic
    .readValue()
    .then(value => {
      console.log(value.getUint16(0, true));
      return value.getUint16(0, true);
    })
    .catch(error => {
      console.log('Argh! getSerialNumber ' + error);
    });

const Vend = props => {
  const { jwt } = props;

  const [stage, setStage] = useState(SCAN); //scan, dropoff
  const [data, setData] = useState('');
  const [error, setError] = useState('');
  const [loading, setloading] = useState(false);
  const [amount, setAmount] = useState(0.0);
  const [enable, setEnable] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [bleMsg, setBleMsg] = useState('');

  const mid = getParameterByName('mid', data);
  const enableScanner = !mid && !loading && stage === SCAN;
  const showUserInput = stage === USER_INPUT && mid;

  const disconnectHandler = useCallback(
    device => {
      setEnable(true);
    },
    [setEnable]
  );

  const connectHandler = useCallback(
    selectedDevice => {
      setEnable(false);
      selectedDevice.addEventListener(
        'gattserverdisconnected',
        disconnectHandler
      );
    },
    [setEnable]
  );

  useEffect(() => {
    if (mid && stage === SCAN) {
      setStage(USER_INPUT);
    }
  }, [mid]);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }

    if (showError) {
      setTimeout(() => {
        setShowError(false);
        setEnable(true);
      }, 2000);
    }
  });

  return (
    <Container>
      {enableScanner && (
        <QrReader
          delay={300}
          onError={setError}
          onScan={setData}
          style={{ width: '100%' }}
        />
      )}
      {showUserInput && (
        <>
          <div>{`Amount： RM${(Math.round(amount * 100 * 0.5) / 100).toFixed(
            2
          )}`}</div>
          <Button.Group fluid>
            <Button
              icon="minus"
              onClick={() =>
                setAmount(amount => {
                  const afterDeduct = --amount;
                  return afterDeduct > 0 ? afterDeduct : 0;
                })
              }
            />
            <Button.Or />
            <Button icon="plus" onClick={() => setAmount(amount => ++amount)} />
          </Button.Group>
          <br />
          <br />
          <Container>
            <Button
              fluid
              primary
              disabled={!enable || amount <= 0}
              onClick={async () => {
                const device = await navigator.bluetooth
                  .requestDevice({
                    filters: [
                      { services: [SERVICE_UUID] },
                      // { name: 'BoxifyDongle-ABCD123' }
                      { name: mid }
                    ]
                  })
                  .catch(e => {});
                if (!device) {
                  return;
                }
                connectHandler(device);
                const characteristics = await device.gatt
                  .connect()
                  .then(server => {
                    return server.getPrimaryService(SERVICE_UUID);
                  })
                  .then(service => {
                    return service.getCharacteristics();
                  })
                  .catch(error => {
                    console.log(error);
                  });
                try {
                  const getSerialChar = characteristics.find(
                    ({ uuid }) => uuid === CHARACTERISTIC_UUID_TX
                  );
                  const serialNum = await getSerialNumber(getSerialChar);
                  const notifyChar = characteristics.find(
                    ({ uuid }) => uuid === SUCCESS_CHARACTERISTIC_UUID_TX
                  );
                  notifyChar.startNotifications().then(_ => {
                    console.log('> Notifications started');
                    notifyChar.addEventListener(
                      'characteristicvaluechanged',
                      event => {
                        let value = event.target.value;
                        let a = [];

                        for (let i = 0; i < value.byteLength; i++) {
                          a.push(String.fromCharCode(value.getUint8(i)));
                        }
                        console.log('> ' + a.join(''));
                        const result = a.join('');
                        setBleMsg(result);
                        if (result.split('SUCCESS').length > 1) {
                          setShowSuccess(true);
                        } else {
                          setShowError(true);
                        }
                        device.gatt.disconnect();
                      }
                    );
                  });

                  const { token } = await fetchGenerateKey({
                    serialNum,
                    jwt,
                    payload: {
                      longPulse: 100,
                      longNum: 0,
                      midPulse: 60,
                      midNum: 0,
                      shortPulse: 30,
                      shortNum: amount
                    }
                  }).catch(e => {
                    setShowError(true);
                  });
                  const writeTokenChar = characteristics.find(
                    ({ uuid }) => uuid === CHARACTERISTIC_UUID_RX
                  );
                  const encoder = new TextEncoder('utf-8');
                  console.log('writing token');
                  writeTokenChar
                    .writeValue(encoder.encode(token))
                    .then(r => console.log('write success'));
                } catch (e) {
                  // alert('Unlock failed.');
                  setShowError(true);
                }
              }}
            >
              Pay
            </Button>
            <TransitionablePortal
              open={showSuccess}
              transition={{ duration: 2000 }}
            >
              <Message success header={bleMsg} />
            </TransitionablePortal>
            <TransitionablePortal
              open={showError}
              transition={{ duration: 2000 }}
            >
              <Message error header={bleMsg} />
            </TransitionablePortal>
            <Dimmer inverted active={!enable}>
              <Loader />
            </Dimmer>
          </Container>
        </>
      )}
    </Container>
  );
};

export default withJwt(Vend);
