import React from 'react';
import { Image, Container, Grid, Segment, Icon } from 'semantic-ui-react';
// import { branch, renderComponent } from 'recompose';
// import { compose } from 'redux';
import { Link } from 'react-router-dom';
// import { withUserProps, isStaff } from '../../components/utils/role';
import { DRYCLEAN, LAUNDRY } from '../../components/utils/type';
import { PARCEL_PICKUP } from '../../';

const Home = () => {
  return (
    <>
      <Segment.Group raised horizontal>
        <Segment>Left</Segment>
        <Segment>Right14</Segment>
      </Segment.Group>
      <Container>
        <Grid columns={4} relaxed>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Link
                to={{
                  pathname: '/customer-dropoff',
                  hash: `#${DRYCLEAN}`
                }}
                style={{ color: 'black' }}
              >
                <Image
                  src="https://res.cloudinary.com/dqswju9jp/image/upload/v1567328495/superwash/ic_dry_cleaning-512.png"
                  size="small"
                  circular
                />
                Dry clean
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Link
                to={{
                  pathname: '/customer-dropoff',
                  hash: `#${LAUNDRY}`
                }}
                style={{ color: 'black' }}
              >
                <Image
                  src="https://res.cloudinary.com/dqswju9jp/image/upload/v1567330753/superwash/wash_fold_1.png"
                  size="small"
                  circular
                />
                Wash & fold
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Link to="/customer-dropoff" style={{ color: 'black' }}>
                <Image
                  src="https://res.cloudinary.com/dqswju9jp/image/upload/v1567337658/superwash/key.png"
                  size="small"
                  circular
                />
                Keys
              </Link>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              {/* <Image
                src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                size="small"
                circular
              /> */}
              <Link to={PARCEL_PICKUP} style={{ color: 'black' }}>
                <Icon.Group size="big">
                  <Icon name="box" />
                  <Icon corner name="angle double up" />
                </Icon.Group>
              </Link>
              Pickup parcel
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Link to="/vend" style={{ color: 'black' }}>
                <Icon name="hand paper" size="big" />
                Vend
              </Link>
            </Grid.Column>
            <Grid.Column>
              {/* <Image
                src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                size="small"
                circular
              /> */}
              <Link to="/lock" style={{ color: 'black' }}>
                <Icon name="lock" size="big" />
                Lock
              </Link>
            </Grid.Column>
            <Grid.Column>
              {/* <Image
                src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                size="small"
                circular
              /> */}
              <Link to="/bed" style={{ color: 'black' }}>
                <Icon name="bed" size="big" />
                Bed
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Image
                src="https://react.semantic-ui.com/images/wireframe/square-image.png"
                size="small"
                circular
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

// const enhance = compose(
//   withUserProps,
//   branch(isStaff, renderComponent(() => <Redirect to='/staff-pickup'/>))
// );
export default Home;
