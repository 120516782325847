import { combineReducers } from 'redux';
import { ON_BLUR, ON_FOCUS } from './actions';

const isVisible = (state = true, { type }) => {
  switch (type) {
    case ON_BLUR:
      return true;
    case ON_FOCUS:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isVisible
});
