import React, { useState, useEffect, useCallback } from 'react';
import 'whatwg-fetch';
import {
  Button,
  Container,
  Dimmer,
  Loader,
  Form,
  Radio,
  Segment,
  Header,
  Message,
  TransitionablePortal
} from 'semantic-ui-react';
import aesjs from 'aes-js';

import { withJwt } from '../../components/utils/role';
import { handleErrors } from '../../components/Common';
// const fetchGenerateKey = ({ serialNum, jwt }) => {
//   return fetch(
//     `${process.env.REACT_APP_CMS_HOST}/locks/generate-key?serialNum=${serialNum}`,
//     {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${jwt}`
//       }
//     }
//   )
//     .then(handleErrors)
//     .then(response => response.json());
// };

const SERVICE_UUID = 0xfee7;
const CHARACTERISTIC_UUID_RX = '000036f5-0000-1000-8000-00805f9b34fb';
const CHARACTERISTIC_UUID_TX = '000036f5-0000-1000-8000-00805f9b34fb';
const SUCCESS_CHARACTERISTIC_UUID_TX = '000036f6-0000-1000-8000-00805f9b34fb';
const key = Buffer.from('153639503D58284D48285F1D4606334F', 'hex');

const GET_TOKEN = 0;
const OPEN_LOCK = 1;
const CLOSE_LOCK = 2;

const Lock = props => {
  const { jwt } = props;
  const [enable, setEnable] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [stage, setStage] = useState(OPEN_LOCK);
  const [lockToken, setLockToken] = useState('');
  const [aesEcb] = useState(new aesjs.ModeOfOperation.ecb(key));
  const [writeChar, setWriteChar] = useState('');
  const [deviceState, setDeviceState] = useState('');
  //   const [selectedDevice, setSelectedDevice] = useState(null);
  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    }

    if (showError) {
      setTimeout(() => {
        setShowError(false);
        setEnable(true);
      }, 2000);
    }
  });

  useEffect(() => {
    if (!lockToken) {
      return;
    }
    if (stage === OPEN_LOCK) {
      const lockPassword = [59, 32, 69, 45, 18, 5];
      const payload = [5, 1, 6, ...lockPassword, ...lockToken, 1, 2, 3];
      const encryptedBytes = aesEcb.encrypt(payload);
      writeChar
        .writeValue(encryptedBytes)
        .then(r => {
          console.log('open lock write');
          deviceState.gatt.disconnect();
        })
        .catch(console.error);
    } else if (stage === CLOSE_LOCK) {
      const payload = [5, 0x0c, 1, 1, ...lockToken, 1, 2, 3, 4, 5, 6, 7, 8];
      const encryptedBytes = aesEcb.encrypt(payload);
      writeChar
        .writeValue(encryptedBytes)
        .then(r => {
          console.log('close lock write');
          deviceState.gatt.disconnect();
        })
        .catch(console.error);
    }
    return () => {
      // cleanup;
    };
  }, [stage, lockToken]);

  const disconnectHandler = useCallback(
    device => {
      console.log('disconnecting');
      setDeviceState('');
      setLockToken('');
      setWriteChar('');
      setEnable(true);
    },
    [setEnable]
  );

  const connectHandler = useCallback(
    selectedDevice => {
      setEnable(false);
      console.log(selectedDevice);
      selectedDevice.addEventListener(
        'gattserverdisconnected',
        disconnectHandler
      );
    },
    [setEnable]
  );

  return (
    <Container>
      <Form>
        <Form.Field>
          <Radio
            label="Open"
            name="radioGroup"
            value={OPEN_LOCK}
            checked={stage === OPEN_LOCK}
            onChange={(e, { value }) => setStage(value)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Close"
            name="radioGroup"
            value={CLOSE_LOCK}
            checked={stage === CLOSE_LOCK}
            onChange={(e, { value }) => setStage(value)}
          />
        </Form.Field>
      </Form>
      <Button
        primary
        disabled={!enable}
        onClick={async () => {
          const device = await navigator.bluetooth.requestDevice({
            filters: [{ services: [SERVICE_UUID] }]
          });
          setDeviceState(device);
          connectHandler(device);
          const characteristics = await device.gatt
            .connect()
            .then(server => {
              console.log(server);

              return server.getPrimaryService(SERVICE_UUID);
            })
            .then(service => {
              console.log(service);
              return service.getCharacteristics();
            })
            .catch(error => {
              console.log(error);
            });
          try {
            console.log(characteristics);

            // var key =  Buffer.from('3A60432A5C01211F291E0F4E0C132825', 'hex');

            console.log(aesjs.utils.hex.fromBytes(key));

            var textBytes = Buffer.from(
              '060101012D1A683D48271A18316E471A',
              'hex'
            );

            console.log(`data bytes ${aesjs.utils.hex.fromBytes(textBytes)}`);

            var encryptedBytes = aesEcb.encrypt(textBytes);
            var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
            console.log(encryptedHex);
            // const getSerialChar = characteristics.find(
            //   ({ uuid }) => uuid === CHARACTERISTIC_UUID_TX
            // );
            // const serialNum = await getSerialNumber(getSerialChar);
            const notifyChar = characteristics.find(
              ({ uuid }) => uuid === SUCCESS_CHARACTERISTIC_UUID_TX
            );
            await notifyChar.startNotifications().then(_ => {
              console.log('> Notifications started');
              notifyChar.addEventListener(
                'characteristicvaluechanged',
                event => {
                  const value = event.target.value;
                  // let a = [];
                  const a = Array.from({ length: value.byteLength }, (x, i) =>
                    value.getUint8(i)
                  );
                  const decryptedBytes = aesEcb.decrypt(a); //throw error if failed
                  if (decryptedBytes.slice(0, 2).join('') === '62') {
                    setLockToken(decryptedBytes.slice(3, 7));
                  }
                }
              );
            });
            const writeTokenChar = characteristics.find(
              ({ uuid }) => uuid === CHARACTERISTIC_UUID_RX
            );
            setWriteChar(writeTokenChar);
            await writeTokenChar
              .writeValue(encryptedBytes)
              .then(r => console.log('write get token'));
          } catch (e) {
            // alert('Unlock failed.');
            setShowError(true);
          }
        }}
      >
        Comm lock
      </Button>
      <TransitionablePortal open={showSuccess}>
        <Message success header="Unlock successful." />
      </TransitionablePortal>
      <TransitionablePortal open={showError}>
        <Message error header="Unlock failed." />
      </TransitionablePortal>
      <Dimmer inverted active={!enable}>
        <Loader />
      </Dimmer>
    </Container>
  );
};

export default withJwt(Lock);
