import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getUser, getJwt } from '../../views/Auth/reducer';

export const isStaff = props =>
  props.user && props.user.role 
  && (props.user.role.type === 'staff' || props.user.role.type === 'laundry_dry_clean_staff');

export const isCustomer = props =>
  props.user && props.user.role && props.user.role.type === 'authenticated';

const showIfIsCustomer = WrappedComponent => ({ user, ...props }) => {
  if (isCustomer({ user })) {
    return <WrappedComponent {...props} />;
  }
  return null;
};

const showIfIsStaff = WrappedComponent => ({ user, ...props }) => {
  if (isStaff({ user })) {
    return <WrappedComponent {...props} />;
  }
  return null;
};

export const withUserProps = connect(
  state => ({
    user: getUser(state.auth)
  }),
  () => ({})
);

export const withJwt = connect(
  state => ({
    jwt: getJwt(state.auth)
  }),
  () => ({})
);

export const displayIfCustomer = compose(
  withUserProps,
  showIfIsCustomer
);

export const displayIfStaff = compose(
  withUserProps,
  showIfIsStaff
);