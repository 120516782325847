import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Container,
  Form,
  Dimmer,
  Loader,
  Button,
  Divider,
  Grid
} from 'semantic-ui-react';
import SocialLink from './SocialLink';
import { submitLogin } from './actions';

const Auth = ({ login, isLoading }) => {
  const [identifier, setIdentifier] = useState('customer@boxify.io');
  const [password, setPassword] = useState('password');
  // const [identifier, setIdentifier] = useState('staff@boxify.io');
  // const [password, setPassword] = useState('password');
  return (
    <Container>
      <Form>
        <Form.Input
          onChange={e => setIdentifier(e.target.value)}
          icon="at"
          iconPosition="left"
          label="Email"
          placeholder="john@doe.com"
          required
          autoComplete="email"
          value={identifier}
        />
        <Form.Input
          onChange={e => setPassword(e.target.value)}
          autoComplete="current-password"
          icon="lock"
          iconPosition="left"
          label="Password"
          type="password"
          required
          value={password}
        />
        {/* <Form.Field>
            <Checkbox label='I agree to the Terms and Conditions' />
            </Form.Field> */}
        {/* <Button type='submit'>Login</Button> */}
        <Grid columns={2} textAlign='center'>
          <Grid.Row>
            <Grid.Column>
              <Button primary onClick={() => login({ identifier, password })}>
                Login
              </Button>
            </Grid.Column>
            <Grid.Column verticalAlign='middle'>
              <Link to="/signup">Sign up</Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      <Divider horizontal>Or</Divider>
      <SocialLink provider={'facebook'} />
      <Dimmer inverted active={isLoading}>
        <Loader active={isLoading}>Logging you in...</Loader>
      </Dimmer>
    </Container>
  );
};

const mapStateToProps = state => ({
  isLoading: state.auth.isFetching > 0
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    login: args => {
      dispatch(submitLogin(args)).then(p => {
        ownProps.history.replace('/');
        return p;
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
