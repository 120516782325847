import React, { useState, useEffect } from 'react';
import ReduxToastr from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Provider, createClient } from 'urql';

import { isMenuVisible } from './components/NavBar/reducer';
import { closeMenu } from './components/NavBar/actions';
import NavBar from './components/NavBar';
import FirebaseMessaging from './components/PushNotification';
import BottomMenu from './views/BottomMenu';
import { withIosSafariOnly } from './components/DeviceSupport/withIosSafariOnly';

import './App.css';

const client = createClient({
  url: `${process.env.REACT_APP_CMS_HOST}/graphql`,
  fetchOptions: {
    headers: {
      Authorization: 'Bearer xxxxx'
    }
  }
});

const App = props => {
  const { jwt } = props;

  const fetchOptions = Object.assign(client.fetchOptions, {
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  });
  client.fetchOptions = fetchOptions;

  useEffect(() => {
    const opts = Object.assign(client.fetchOptions, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    });
    client.fetchOptions = opts;
  }, [jwt]);

  return (
    <div>
      <Provider value={client}>
        {/* <ReduxToastr
          timeOut={4000}
          newestOnTop
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        /> */}
        <FirebaseMessaging />
        <NavBar />
        {props.children}
      </Provider>
      <BottomMenu />
    </div>
  );
};

App.propTypes = {
  // sideMenuVisible: PropTypes.bool.isRequired,
  // closeMenu: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

App.defaultProps = {
  children: null
};

const mapStateToProps = state => ({
  // sideMenuVisible: isMenuVisible(state.navbar),
  jwt: state.auth.jwt
});

const enhance = compose(
  withIosSafariOnly,
  connect(
    mapStateToProps
    // { closeMenu }
  )
);

export default enhance(App);
