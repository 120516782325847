import React, { Fragment, useState, useEffect } from 'react';
import 'whatwg-fetch';
import {
  Button,
  Step,
  Loader,
  Modal,
  Icon,
  Header,
  Message,
  Container,
  Dimmer
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import QrReader from 'react-qr-reader';
import { connect } from 'react-redux';
import { getJwt } from '../../views/Auth/reducer';
import { handleErrors } from '../../components/Common';
import getParameterByName from '../../components/utils/getParameterByName';
import { DRYCLEAN, LAUNDRY } from '../../components/utils/type';

const SCAN = 'scan';
const DROPOFF = 'dropoff';
const STEPS = [
  {
    key: SCAN,
    icon: 'qrcode',
    title: 'Scan locker QR',
    active: true
    //   description: 'to open',
  },
  {
    key: DROPOFF,
    icon: 'dropdown',
    title: 'Drop off'
    //   description: 'Put',
  }
];

const CustomerDropoff = props => {
  const {
    jwt,
    location,
  } = props;
  const { hash } = location;
  const [steps, updateSteps] = useState(STEPS);
  const [data, setData] = useState('');
  const [job, setJob] = useState('');
  const [error, setError] = useState('');
  const [loading, setloading] = useState(false);
  const [stage, setStage] = useState(SCAN); //scan, dropoff
  const hasError = !!error;
  const ref = getParameterByName('ref', data);
  const enableScanner = !ref && !loading && stage === SCAN;
  const jobType = [DRYCLEAN, LAUNDRY].find(t => hash === `#${t}`);

  useEffect(() => {
    updateSteps(steps =>
      steps.map(s => {
        if (s.key === stage) {
          return {
            ...s,
            active: true
          };
        } else {
          return {
            ...s,
            active: false
          };
        }
      })
    );
  }, [stage]);

  useEffect(() => {
    if (ref) {
      setloading(true);
      const url = `${process.env.REACT_APP_CMS_HOST}/${jobType}/${ref}/`;
      fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`
        },
        method: 'POST'
      })
        .then(handleErrors)
        .then(response => response.json())
        .then(json => {
          setStage(DROPOFF);
          setData('');
          setJob(json.message);
          setloading(false);
        })
        .catch(err => {
          setData('');
          setError(err);
          setloading(false);
        });
    }
  }, [data]);

  return (
    <Fragment>
      <Step.Group unstackable size="mini" widths={2} items={steps} />
      {loading && (
        <Dimmer active inverted>
          <Loader inverted>Opening a box...</Loader>
        </Dimmer>
      )}
      {!hasError && enableScanner && (
        <QrReader
          delay={300}
          onError={setError}
          onScan={setData}
          style={{ width: '100%' }}
        />
      )}
      {stage === DROPOFF && (
        <Container>
          <Message positive icon>
            <Icon name="check" />
            <Message.Content>
              <Message.Header>
                Your order number is {job.jobNumber}
              </Message.Header>
              <p>Please drop off into locker now. </p>
            </Message.Content>
          </Message>
          <Button as={Link} to='/orders' primary fluid>
            View my orders
          </Button>
        </Container>
      )}
      <Modal basic size="small" open={hasError}>
        <Header icon="archive" content="Sorry, no box are available" />
        <Modal.Content>
          <p>No boxes are available at the moment. Call for support.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" inverted onClick={() => setError('')}>
            <Icon name="checkmark" /> OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  jwt: getJwt(state.auth)
});

export default connect(mapStateToProps)(CustomerDropoff);
