import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import { HashRouter, Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Loader, Transition } from 'semantic-ui-react';
import configureStore from './configureStore';
import App from './App';
// import MessengerExtension from './components/MessengerExtension/index';

import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
// import Products from './views/Products';
// import Categories from "./views/Categories";
import LoadableOrders from './views/Orders/Loadable';
import LoadableOrderDetail from './views/OrderDetail/Loadable';
// import Product from './views/Product';
// import Cart from './views/Cart';
// import Search from './views/Search';
// import Checkout from './views/Checkout';
import Auth from './views/Auth';
import CustomerDropoff from './views/CustomerDropoff';
import LoadableProfile from './views/Profile/Loadable';
import ProtectedRoute from './components/ProtectedRoute';
import LoadableUserDetail from './views/UserDetail/Loadable';
import LoadableSignup from './views/Signup/Loadable';
import LoadableParcelPickup from './views/ParcelPickup/Loadable';
import Vend from './views/Vend';
import Lock from './views/Lock';
import NokeLock from './views/NokeLock';

import StorageManager from './components/StorageManager';
import Worker from './components/RegisterSW';

// import './index.css';

const { persistor, store } = configureStore();

export const HOME = '/';
export const ORDERS = '/orders';
export const MY_PROFILE = '/my-profile';
export const PARCEL_PICKUP = '/parcel-pickup';

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });

//     navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
// }

render(
  <Provider store={store}>
    <ReduxToastr
      timeOut={4000}
      newestOnTop
      preventDuplicates
      position="top-center"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
    />
    <Worker />
    <HashRouter>
      <PersistGate
        loading={<Loader />}
        // onBeforeLift={onBeforeLift}
        persistor={persistor}
      >
        <StorageManager persistor={persistor} />
        <App>
          <Switch>
            <Route exact path={HOME} component={Home} />
            <Route exact path="/vend" component={Vend} />
            <Route exact path="/lock" component={Lock} />
            <Route exact path="/bed" component={NokeLock} />

            <Route path="/signup" component={LoadableSignup} />

            {/* <Route path="/categories" component={Categories} /> */}
            {/* <Route path="/category/:categId" component={Products} /> */}
            {/* <Route path="/product/:productId" component={Product} /> */}
            {/* <Route path="/search/:search" component={Search} /> */}
            {/* <Route path="/cart" component={Cart} /> */}
            {/* <Route path="/checkout" component={Checkout} /> */}
            {/* <ProtectedRoute path={ORDERS} component={Orders} /> */}
            <Route
              path={ORDERS}
              render={({ match: { path } }) => (
                <>
                  <ProtectedRoute
                    path={`${path}/`}
                    component={LoadableOrders}
                    exact
                  />
                  <ProtectedRoute
                    path={`${path}/:type/:id`}
                    component={LoadableOrderDetail}
                  />
                </>
              )}
            />
            <Route
              path={MY_PROFILE}
              render={({ match: { path } }) => (
                <>
                  <ProtectedRoute
                    path={`${path}/`}
                    component={LoadableProfile}
                    exact
                  />
                  <ProtectedRoute
                    path={`${path}/detail`}
                    component={LoadableUserDetail}
                  />
                </>
              )}
            />
            <ProtectedRoute
              path="/customer-dropoff"
              component={CustomerDropoff}
            />
            <Route
              path={PARCEL_PICKUP}
              render={({ match: { path } }) => (
                <>
                  <ProtectedRoute
                    path={`${path}/`}
                    component={LoadableParcelPickup}
                    exact
                  />
                  <ProtectedRoute
                    path={`${path}/:code`}
                    component={LoadableParcelPickup}
                  />
                </>
              )}
            />
            <Route path="/auth/login" component={Auth} />
          </Switch>
        </App>
      </PersistGate>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

// registerServiceWorker();
